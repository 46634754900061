<template>
<loader v-bind="{ loading }">
  <div class="box">
    <level :title="Naming.Sites">
      <div slot="right">
        <text-input
          classes="level-item is-rounded"
          v-model="filters.search"
          @input="filterBy('search', ...arguments)"
          :placeholder="`Search ${Naming.Sites.toLowerCase()}…`">
          <div slot="right">
            <action-button @click="searchSites" left-icon="search">Search</action-button>
            <router-link :to="{ name: 'site-admin-create', query: { client: $route.params.client }}" class="button is-info is-rounded">
              <icon icon="plus"/>
              <span>New {{ Naming.Site }}</span>
            </router-link>
          </div>
        </text-input>
      </div>
    </level>

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
          <th>{{ Naming.Zones }}</th>
          <th>{{ Naming.Assets }}</th>
          <th>{{ Naming.Jobs }}</th>
          <th class="has-text-right">Current Risk</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="site in sites.data" :key="site.id">
          <td>
            <link-site-manager :site="site"/>
          </td>
          <td>{{ site.zones_count }}</td>
          <td>{{ site.assets_count }}</td>
          <td>{{ site.jobs_count }}</td>
          <td class="has-text-right">
            <span v-if="site.risk" :class="`risk ${site.risk.level.toLowerCase()}`">{{ site.risk.level }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="columns">
      <div class="column">
        <pager :pageable="sites" @nav="loadSites" :context="Naming.Site.toLowerCase()"/>
      </div>
      <div class="column is-narrow">
        <router-link 
          :to="{
            name: 'client-deleted-sites',
            params: {
              client: client.id
            }
          }"
          class="is-rounded button is-small">
            <icon icon="trash"/>
            <span>Show Deleted {{ Naming.Sites }}</span>
          </router-link>
      </div>
    </div>
  </div>
</loader>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixins, utilities } from '@/mixins/js-helpers'

export default {

  mixins: [mixins.filterBy],

  name: 'client-sites',

  data: () => ({
    loading: true,
    namespace: 'site',
    path: '',
    filters: {
      search: ''
    }
  }),

  async created() {
    this.path = `/api/client/${this.$route.params.client}/sites`
    await this.loadSites(this.path + utilities.cleanQuery(this.filters))
    this.loading = false
  },

  methods: {
    loadSites(path) {
      return this.$store.dispatch(
        'site/loadSitesByClient',
        path
      )
    },
    searchSites() {
      this.$store.dispatch(
        'site/loadSitesByClient',
        this.path + utilities.cleanQuery(this.filters)
      )
      this.$router.push({
        name: this.$options.name,
        query: this.filters
      })
    }
  },

  computed: {
    ...mapGetters('client', [
      'client'
    ]),
    ...mapGetters('site', [
      'sites'
    ])
  }

}
</script>
